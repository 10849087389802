<template>
  <vca-card>
    <vca-card class="text-center shadowed">
      <h1>{{ text }}</h1>
    </vca-card>
  </vca-card>
</template>
<script>
export default {
  name: "LoadingMessage",
  props: {
    text: {
      type: String,
      default() {
        return this.$t("messages.loading.data");
      },
    },
  },
};
</script>
